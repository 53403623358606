<template>
  <v-row>
    <create-renewal />
  </v-row>
</template>

<script>

import CreateRenewal from '../components/subscriptionRenewal/CreateRenewal.vue';

export default {
  name: 'Coinbase',
  components: {
    CreateRenewal,

  },
};
</script>
